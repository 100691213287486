import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Button from "@ui/button";
import { SectionWrap, JobList, JobItem, JobTitle, JobType, JobDesc, JobButton } from "./style";


const DataArea = () => {
	const getOpenData = useStaticQuery(graphql`
        query GET_OPEN_DATA {
            intro: openSourceJson(id: {eq: "data-intro"}) {
                title
            }
            jobs: openSourceJson(id: {eq: "our-open-data"}) {
                data {
                    id
                    title
                    link
                    description
                }
            }
        }      
    `);
	const { intro: { title }, jobs: { data } } = getOpenData

	return (
		<SectionWrap>
			<Container>
				{/* <Row justify="center">
					<Col lg={7}>
						<SectionTitle title={title} />
					</Col>
				</Row> */}
				<Row>
					<Col lg={12}>
						<JobList>
							{data.map((job, i) => {
								const isEven = i % 2 === 0;
								return (
									<JobItem key={job.id} isEven={isEven}>
										<Row>
											<Col lg={3}>
												<JobTitle>{job.title}</JobTitle>
											</Col>
											<Col lg={6}>
												<div dangerouslySetInnerHTML={{__html:job.description}}></div>
											</Col>
											<Col lg={3}>
												<JobButton>
													<Button varient="outlined" bordercolor="#ddd" onClick={()=>{window.location.href=job.link}}>Download</Button>
												</JobButton>
											</Col>
										</Row>
									</JobItem>
								)
							})}
						</JobList>
					</Col>
				</Row>
			</Container>
		</SectionWrap>
	)
}

export default DataArea
